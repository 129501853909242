import React from "react";

function FormSection({ children }) {
  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">{children}</div>
    </div>
  );
}

export default FormSection;
