import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
} from "../components/Table";
import { LinkButton } from "../components/Button";
import { DocumentDownloadIcon } from "@heroicons/react/solid";

const incomeList = [
  {
    id: 8746,
    date: "2021-08-11T03:55:17.290Z",
    income: 24150,
    amount: 1,
    bank: "ХААН банк",
    bankAccountInfo: "Наранцэцэг / 5031363858",
  },
  {
    id: 8746,
    date: "2021-08-11T03:55:17.290Z",
    income: 24150,
    amount: 1,
    bank: "ХААН банк",
    bankAccountInfo: "Наранцэцэг / 5031363858",
  },
  {
    id: 8746,
    date: "2021-08-11T03:55:17.290Z",
    income: 24150,
    amount: 1,
    bank: "ХААН банк",
    bankAccountInfo: "Наранцэцэг / 5031363858",
  },
  {
    id: 8746,
    date: "2021-08-11T03:55:17.290Z",
    income: 24150,
    amount: 1,
    bank: "ХААН банк",
    bankAccountInfo: "Наранцэцэг / 5031363858",
  },
  {
    id: 8746,
    date: "2021-08-11T03:55:17.290Z",
    income: 24150,
    amount: 1,
    bank: "ХААН банк",
    bankAccountInfo: "Наранцэцэг / 5031363858",
  },
  {
    id: 8746,
    date: "2021-08-11T03:55:17.290Z",
    income: 24150,
    amount: 1,
    bank: "ХААН банк",
    bankAccountInfo: "Наранцэцэг / 5031363858",
  },
];

function IncomeTable() {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Дугаар</TableHeaderCell>
          <TableHeaderCell>Огноо</TableHeaderCell>
          <TableHeaderCell>Олгосон дүн</TableHeaderCell>
          <TableHeaderCell>Борлуулалт</TableHeaderCell>
          <TableHeaderCell>Банк</TableHeaderCell>
          <TableHeaderCell>Данс</TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {incomeList.map((incomeInfo, idx) => (
          <TableRow key={idx}>
            <TableCell className="text-sm">{incomeInfo.id}</TableCell>
            <TableCell className="text-sm">
              {new Date(incomeInfo.date).toLocaleDateString()}
            </TableCell>
            <TableCell className="text-sm">{incomeInfo.income}</TableCell>
            <TableCell className="text-sm">{incomeInfo.amount}</TableCell>
            <TableCell className="text-sm">{incomeInfo.bank}</TableCell>
            <TableCell className="text-sm">
              {incomeInfo.bankAccountInfo}
            </TableCell>
            <TableCell className="text-sm">
              <LinkButton className="ml-20 flex items-center justify-center">
                <DocumentDownloadIcon
                  className="h-5 w-5 mr-2"
                  aria-hidden="true"
                />
                <span>Татах</span>
              </LinkButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default IncomeTable;
