import React from "react";

function FormSectionHeader({ children }) {
  return (
    <div className="md:col-span-1">
      <div className="px-4 sm:px-0">{children}</div>
    </div>
  );
}

export default FormSectionHeader;
