import Label from "../Label";
import Input from "../Input";
import { SearchIcon, CheckIcon } from "@heroicons/react/solid";
import { useReducer, useRef } from "react";
import { useWindowEvent } from "../../hooks";

const reducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case "OPEN_OPTIONS":
      return { ...state, open: true };
    case "CLOSE_OPTIONS":
      return { ...state, open: false };
    case "TOGGLE_OPTIONS":
      return { ...state, open: !state.open };
    default:
      return { ...state };
  }
};

const initialState = { open: false };

function SearchField(props) {
  const {
    label,
    value: selectedValue,
    onChange,
    onSearch,
    name,
    searchTerm,
    items,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const inputRef = useRef(null);
  const optionsRef = useRef();
  const { open } = state;

  // Handle outside click
  useWindowEvent("mousedown", (event) => {
    const target = event.target;

    if (!open) return;

    if (inputRef.current.contains(target)) return;
    if (optionsRef.current.contains(target)) return;

    dispatch({ type: "CLOSE_OPTIONS" });
  });

  useWindowEvent("keydown", (event) => {
    const key = event.key;

    switch (key) {
      case "Escape":
        if (!open) return;
        dispatch({ type: "CLOSE_OPTIONS" });
        break;

      default:
        break;
    }
  });

  return (
    <div className="relative">
      <Label>{label}</Label>
      <div
        className="mt-1 relative rounded-md shadow-sm w-full"
        ref={inputRef}
        onClick={() => {
          dispatch({ type: "TOGGLE_OPTIONS" });
        }}
      >
        <Input
          type="text"
          className={`block w-full rounded-md pr-10`}
          autoComplete="off"
          placeholder="Хайх"
          value={searchTerm}
          name={name}
          onChange={(event) => {
            onSearch(event);

            dispatch({
              type: "OPEN_OPTIONS",
            });
          }}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <span className="px-3">
            <SearchIcon className="w-5 h-5 text-blue-500" aria-hidden="true" />
          </span>
        </div>
      </div>

      {open && (
        <ul
          className="absolute w-full py-1 mt-1 
                    overflow-auto text-base bg-white 
                    rounded-md shadow-lg max-h-60 
                    ring-1 ring-black ring-opacity-5 
                    focus:outline-none sm:text-sm"
          ref={optionsRef}
        >
          {items.map(({ value, label }) => (
            <li
              key={value}
              className="cursor-default select-none relative py-2 pl-10 pr-4 
                     hover:bg-blue-500 hover:text-white"
              onClick={() => {
                onChange({
                  target: {
                    type: "search-and-select",
                    name,
                    value,
                    searchTerm: label,
                  },
                });

                dispatch({
                  type: "CLOSE_OPTIONS",
                });
              }}
            >
              <span className="block truncate">{label}</span>
              {!!selectedValue && value === selectedValue && (
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 ">
                  <CheckIcon className="w-5 h-5" aria-hidden="true" />
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SearchField;
