import { useCallback, useEffect, useReducer, useRef } from "react";

const reducer = (formState, event) => {
  const { type, payload } = event;
  const { currentIndex } = formState;
  let addend = 0;

  switch (type) {
    case "ADD":
      addend = 1;
      break;
    case "DELETE":
      addend = -1;
      break;
    default:
      break;
  }

  return {
    ...formState,
    data: {
      ...payload,
      digits: payload.digits.map((digit, index) => {
        return { ...digit, isActive: index === currentIndex + addend };
      }),
    },
    currentIndex: currentIndex + addend,
  };
};

const initialize = (initialVerificationCodeState) => {
  const { data, length } = initialVerificationCodeState;
  const { name } = data;

  return {
    length,
    currentIndex: 0,
    data: {
      ...data,
      digits: [...Array(length).keys()].map((index) => {
        return {
          index,
          name,
          value: "",
          isActive: index === 0,
        };
      }),
    },
  };
};

function useVerificationCode(initialVerificationCodeState) {
  const [formState, dispatch] = useReducer(
    reducer,
    initialize(initialVerificationCodeState)
  );
  const digitsRef = useRef([]);

  const isNumber = useCallback((value) => {
    const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

    return digits.some((digit) => digit === value);
  }, []);

  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;

      if (isNumber(value)) {
        const { currentIndex } = formState;
        let formField = { ...formState.data };

        const digits = [
          ...formField.digits.slice(0, currentIndex),
          { ...formField.digits[currentIndex], value },
          ...formField.digits.slice(currentIndex + 1),
        ];

        formField = {
          ...formField,
          digits,
          value: digits
            .map((digit) => digit.value)
            .reduce((acc, curr) => acc + curr, ""),
        };

        dispatch({ type: "ADD", payload: formField });
      }
    },
    [ isNumber, formState]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Backspace") {
        let formField = { ...formState.data };
        const { currentIndex, length } = formState;
        let digits = [];

        if (currentIndex > 0) {
          if (currentIndex === length) {
            digits = [
              ...formField.digits.slice(0, currentIndex - 1),
              {
                index: currentIndex - 1,
                name: formField.name,
                value: "",
                isActive: false,
              },
              ...formField.digits.slice(currentIndex + 1),
            ];
          } else {
            digits = [
              ...formField.digits.slice(0, currentIndex - 1),
              {
                index: currentIndex - 1,
                name: formField.name,
                value: "",
                isActive: false,
              },
              {
                index: currentIndex,
                name: formField.name,
                value: "",
                isActive: false,
              },
              ...formField.digits.slice(currentIndex + 1),
            ];
          }

          formField = {
            ...formField,
            digits,
            value: digits
              .map((digit) => digit.value)
              .reduce((acc, curr) => acc + curr, ""),
          };

          dispatch({ type: "DELETE", payload: formField });
        }
      }
    },
    [formState]
  );

  const canSubmit = useCallback((formState) => {
    const { length } = formState;
    const { value } = formState.data;

    return length === value.length;
  }, []);

  const submitData = useCallback((formState) => {
    console.log("Submitting...");
    console.log(formState.data.value);
    console.log("Submitted");
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (canSubmit(formState)) {
        submitData(formState);
      }
    },
    [formState, canSubmit, submitData]
  );

  useEffect(() => {
    if (!!digitsRef && digitsRef.current.length > 0) {
      const { currentIndex } = formState;
      const MAX_LENGTH = formState.length;
      if (0 <= currentIndex && currentIndex < MAX_LENGTH) {
        digitsRef.current[currentIndex].focus();
      }
    }
  }, [formState]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return { formState, handleChange, handleSubmit, digitsRef };
}

export default useVerificationCode;
