import React from "react";
import Label from "../Label";
import Input from "../Input";
import Error from "../Error";

function TextField(props) {
  const { label, ...other } = props;
  const error = other.error;

  return (
    <>
      <Label>{label}</Label>
      <Input
        type="text"
        className={`mt-1 block w-full rounded-md`}
        {...other}
      />
      <Error>{error}</Error>
    </>
  );
}

export default TextField;
