import { useCallback, useReducer } from "react";
import useStep from "./useStep";

const formReducer = (formStates, action) => {
  const { type, payload } = action;
  const { activeStep, formField } = payload;

  switch (type) {
    case "CHANGE": {
      return {
        ...formStates,
        [activeStep]: {
          ...formStates[activeStep],
          [formField.name]: formField,
        },
      };
    }
    case "CHANGE_ARRAY": {
      const { auxParams } = payload;
      const { key, idx } = auxParams;

      return {
        ...formStates,
        [activeStep]: {
          ...formStates[activeStep],
          [key]: formStates[activeStep][key].map((currElem, currIdx) =>
            currIdx === idx
              ? { ...currElem, [formField.name]: formField }
              : currElem
          ),
        },
      };
    }
    case "ADD_TO_ARRAY": {
      const { auxParams } = payload;
      const { key, formField } = auxParams;

      return {
        ...formStates,
        [activeStep]: {
          ...formStates[activeStep],
          [key]: [...formStates[activeStep][key], formField],
        },
      };
    }
    case "REMOVE_FROM_ARRAY": {
      const { auxParams } = payload;
      const { key, idx } = auxParams;

      return {
        ...formStates,
        [activeStep]: {
          ...formStates[activeStep],
          [key]: formStates[activeStep][key].filter(
            (_, currIdx) => currIdx !== idx
          ),
        },
      };
    }
    default:
      return { ...formStates };
  }
};

function useMultiStepForm(initialFormStates, initialActiveStep = 1) {
  const [formStates, dispatch] = useReducer(formReducer, initialFormStates);
  const { activeStep, lastStep, isLastStep, goForward, goBack, goTo } = useStep(
    {
      initialStep: initialActiveStep,
      numberOfSteps: Object.keys(formStates).length,
    }
  );
  const formState = formStates[activeStep];

  const validateFormField = useCallback(({ value, validationRules }) => {
    for (const rule of validationRules) {
      if (!rule.validate(value)) {
        return { isValid: false, error: rule.error };
      }
    }

    return { isValid: true, error: "" };
  }, []);

  const handleChange = useCallback(
    (event, auxParams = null) => {
      if (!!auxParams) {
        const { key, idx } = auxParams;

        const { name, type, value } = event.target;
        let formField = { ...formState[key][idx][name] };

        switch (type) {
          case "checkbox":
            const { checked } = event.target;
            switch (typeof formField.value) {
              case "object":
                const items = formField.items.map((item) => {
                  if (item.value === value) item.checked = checked;
                  return item;
                });

                formField = {
                  ...formField,
                  items,
                  value: items
                    .filter((item) => item.checked)
                    .map((item) => item.value),
                };
                break;
              case "boolean":
                formField = { ...formField, value: checked };
                break;
              default:
                break;
            }
            break;
          case "file":
            const file = event.target.files[0] || null;
            formField = { ...formField, value: file };
            break;
          case "search-and-select":
            const { searchTerm } = event.target;
            formField = { ...formField, searchTerm };
            break;
          case "radio":
          case "select-one":
          case "textarea":
          default:
            formField = { ...formField, value };
            break;
        }

        const { error } = validateFormField(formField);
        formField = { ...formField, error };

        dispatch({
          type: "CHANGE_ARRAY",
          payload: { activeStep, formField, auxParams },
        });
      } else {
        const { name, type, value } = event.target;
        let formField = { ...formState[name] };

        switch (type) {
          case "checkbox":
            const { checked } = event.target;
            switch (typeof formField.value) {
              case "object":
                const items = formField.items.map((item) => {
                  if (item.value === value) item.checked = checked;
                  return item;
                });

                formField = {
                  ...formField,
                  items,
                  value: items
                    .filter((item) => item.checked)
                    .map((item) => item.value),
                };
                break;
              case "boolean":
                formField = { ...formField, value: checked };
                break;
              default:
                break;
            }
            break;
          case "file":
            const file = event.target.files[0] || null;
            formField = { ...formField, value: file };
            break;
          case "search-and-select":
            const { searchTerm } = event.target;
            formField = { ...formField, value, searchTerm };
            break;
          case "radio":
          case "select-one":
          case "textarea":
          default:
            formField = { ...formField, value };
            break;
        }

        const { error } = validateFormField(formField);
        formField = { ...formField, error };

        dispatch({
          type: "CHANGE",
          payload: { activeStep, formField },
        });
      }
    },
    [validateFormField, formState, activeStep]
  );

  const handleSearch = useCallback(
    async (event) => {
      const { name, value: searchTerm } = event.target;
      let formField = { ...formState[name] };

      formField = { ...formField, searchTerm, value: "" };

      const { error } = validateFormField(formField);
      formField = { ...formField, error };

      dispatch({
        type: "CHANGE",
        payload: { activeStep, formField },
      });
    },
    [validateFormField, formState, activeStep]
  );

  const canSubmit = useCallback(
    (formState) => {
      let isFormValid = true;
      for (const name in formState) {
        if (Object.hasOwnProperty.call(formState, name)) {
          if (Array.isArray(formState[name])) {
            for (let idx = 0; idx < formState[name].length; idx++) {
              for (const key in formState[name][idx]) {
                if (Object.hasOwnProperty.call(formState[name][idx], key)) {
                  let formField = { ...formState[name][idx][key] };
                  const { isValid, error } = validateFormField(formField);
                  isFormValid = isFormValid && isValid;
                  formField = { ...formField, error };

                  dispatch({
                    type: "CHANGE_ARRAY",
                    payload: {
                      activeStep,
                      formField,
                      auxParams: {
                        key: name,
                        idx,
                      },
                    },
                  });
                }
              }
            }
          } else {
            let formField = { ...formState[name] };
            const { isValid, error } = validateFormField(formField);
            isFormValid = isFormValid && isValid;
            formField = { ...formField, error };

            dispatch({
              type: "CHANGE",
              payload: { activeStep, formField },
            });
          }
        }
      }
      return isFormValid;
    },
    [validateFormField, activeStep]
  );

  const submitData = useCallback((formState) => {
    const formValues = Object.entries(formState).reduce(
      (formFields, [name, formField]) => ({
        ...formFields,
        [name]: Array.isArray(formField)
          ? formField.map((item) =>
              Object.values(item).reduce(
                (itemField, field) => ({
                  ...itemField,
                  [field.name]: field.value,
                }),
                {}
              )
            )
          : formField.value,
      }),
      {}
    );

    console.log("Submitting...");
    console.log(formValues);
    console.log("Submitted");
  }, []);

  const handleGoForward = useCallback(
    (event) => {
      event.preventDefault();
      if (canSubmit(formState)) {
        submitData(formState);
        goForward();
      }
    },
    [formState, submitData, canSubmit, goForward]
  );

  const handleGoBack = useCallback(
    (event) => {
      event.preventDefault();
      if (isLastStep()) {
        goBack();
      } else {
        if (canSubmit(formState)) {
          submitData(formState);
          goBack();
        }
      }
    },
    [formState, submitData, canSubmit, goBack, isLastStep]
  );

  const handleGoTo = useCallback(
    (nextStep) => {
      if (isLastStep()) {
        goTo(nextStep);
      } else {
        if (canSubmit(formState)) {
          submitData(formState);
          goTo(nextStep);
        }
      }
    },
    [formState, submitData, canSubmit, goTo, isLastStep]
  );

  const handleAdd = useCallback(
    (auxParams) => {
      dispatch({
        type: "ADD_TO_ARRAY",
        payload: { activeStep, auxParams },
      });
    },
    [activeStep]
  );

  const handleRemove = useCallback(
    (auxParams) => {
      dispatch({
        type: "REMOVE_FROM_ARRAY",
        payload: { activeStep, auxParams },
      });
    },
    [activeStep]
  );

  return {
    formStates,
    activeStep,
    lastStep,
    handleChange,
    handleGoForward,
    handleGoBack,
    handleGoTo,
    handleAdd,
    handleRemove,
    handleSearch,
  };
}

export default useMultiStepForm;
