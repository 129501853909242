import { getFileSize } from "./fileHandlers";

export function requiredRule() {
  return {
    name: "required",
    error: "Талбараа бөглөнө үү",
    validate: (value) => {
      switch (typeof value) {
        case "boolean":
          return !!value;
        default:
          return value.length !== 0;
      }
    },
  };
}

export function minLengthRule(minCharacters) {
  return {
    name: "minLength",
    error: `Дор хаяж ${minCharacters} тэмдэгт оруулна уу`,
    validate: (value) => value.length >= minCharacters,
  };
}

export function maxLengthRule(maxCharacters) {
  return {
    name: "maxLength",
    error: `Дээд тал нь ${maxCharacters} тэмдэгт оруулна уу`,
    validate: (value) =>
      (typeof value === "number" ? value.toString() : value).length <=
      maxCharacters,
  };
}

export function fileRequiredRule() {
  return {
    name: "fileRequired",
    error: "Файлаа оруулна уу",
    validate: (file) => !!file,
  };
}

export function fileSizeExceededRule(maxSize) {
  return {
    name: "fileSizeExceeded",
    error: `Дээд тал нь ${getFileSize(maxSize)} хэмжээтэй файл оруулна уу`,
    validate: (file) => !!!file || file.size <= maxSize,
  };
}
