import React from "react";
import {
  Step,
  StepDescription,
  Stepper,
  StepTitle,
} from "../../../components/Step";

const steps = [
  {
    title: "Мэдээлэл",
    description: "Дэлгэрэнгүй мэдээлэл",
  },
  {
    title: "Ангилал",
    description: "Жанр, сэдэв",
  },
  {
    title: "Агуулга",
    description: "Бүтээлийн дэлгэрэнүй",
  },
  {
    title: "Үнэлгээ ба гэрээ",
    description: "Борлуулалтын үнэлгээ",
  },
];

function PublishingStepper({ activeStep, lastStep, handleGoTo }) {
  return (
    <Stepper>
      {steps.map((step, index) => (
        <Step
          key={index + 1}
          number={index + 1}
          isCompleted={index + 1 < lastStep}
          isActive={index + 1 === activeStep}
          onClick={() => {
            handleGoTo(index + 1);
          }}
        >
          <StepTitle>{step.title}</StepTitle>
          <StepDescription>{step.description}</StepDescription>
        </Step>
      ))}
    </Stepper>
  );
}

export default PublishingStepper;
