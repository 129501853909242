import React, { Fragment } from "react";
import {
  TextField,
  TextFieldWithVerification,
  Divider,
  PrimaryButton,
  RadioGroup,
  Select,
} from "../components";
import { Grid, GridItem } from "../components/Grid";
import {
  Section,
  SectionHeader,
  SectionHeaderTitle,
  SectionHeaderDescription,
} from "../components/Section";
import {
  Form,
  FormSection,
  FormSectionHeader,
  FormSectionHeaderTitle,
  FormSectionHeaderDescription,
  FormSectionContent,
} from "../components/Form";
import { useForm } from "../hooks";
import { maxLengthRule, requiredRule } from "../utils/inputValidationRules";

const initialFormState = {
  publisherType: {
    name: "publisherType",
    items: [
      {
        value: "Хувь хүн",
      },
      {
        value: "ААН",
      },
      {
        value: "Бусад",
      },
    ],
    value: "",
    validationRules: [],
    error: "",
  },
  other: {
    label: "",
    name: "other",
    id: "other",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
  name: {
    label: "Бүтэн нэр",
    name: "name",
    id: "name",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
  foreignName: {
    label: "Гадаад нэр",
    name: "foreignName",
    id: "foreignName",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
  email: {
    label: "Цахим шуудангийн хаяг",
    name: "email",
    id: "email",
    value: "",
    validationRules: [requiredRule(), maxLengthRule(255)],
    error: "",
    initialValue: "",
    isVerified: true,
  },
  phoneNumber: {
    label: "Утасны дугаар",
    name: "phoneNumber",
    id: "phoneNumber",
    value: "",
    validationRules: [],
    error: "",
    type: "number",
    initialValue: "",
    isVerified: true,
  },
  address: {
    label: "Хаяг",
    name: "address",
    id: "address",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
  bankName: {
    label: "Банкны нэр",
    items: [
      { value: "Хаан банк" },
      { value: "Голомт банк" },
      { value: "Худалдаа хөгжлийн банк" },
    ],
    name: "bankName",
    id: "bankName",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
  accountHolderName: {
    label: "Дансны нэр",
    name: "accountHolderName",
    id: "accountHolderName",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
  accountNumber: {
    label: "Дансны дугаар",
    name: "accountNumber",
    id: "accountNumber",
    value: "",
    validationRules: [maxLengthRule(30)],
    error: "",
    type: "number",
  },
  taxNumber: {
    label: "Татвар төлөгчийн регистрийн дугаар",
    name: "taxNumber",
    id: "taxNumber",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
};

function Profile() {
  const [formState, handleChange, handleSubmit] = useForm(initialFormState);
  const isOther = formState.publisherType.value === "Бусад";

  return (
    <Fragment>
      <Section>
        <SectionHeader>
          <SectionHeaderTitle>
            Зохиогчийн эрх эзэмшигчийн бүртгэл
          </SectionHeaderTitle>
          <SectionHeaderDescription>
            Зохиогчийн эрх эзэмшигч (ЗЭЭ) нь тухайн бүтээл, бүтээгдэхүүнийг
            нийтлэх, нийтэд түгээх, борлуулах, борлуулалтын орлогыг хүлээн авах
            эрх бүхий хувь хүн эсвэл албан байгууллага байна.
          </SectionHeaderDescription>
        </SectionHeader>
      </Section>

      <Divider />

      <Form onSubmit={handleSubmit}>
        <FormSection>
          <FormSectionHeader>
            <FormSectionHeaderTitle>Нийтлэгчийн төрөл</FormSectionHeaderTitle>
          </FormSectionHeader>
          <FormSectionContent>
            <Grid className="grid-cols-6 gap-2">
              <GridItem className="col-span-6">
                <RadioGroup
                  {...formState.publisherType}
                  onChange={handleChange}
                />
              </GridItem>

              {isOther && (
                <GridItem className="col-span-6">
                  <TextField {...formState.other} onChange={handleChange} />
                </GridItem>
              )}
            </Grid>
          </FormSectionContent>
        </FormSection>

        <Divider />

        <FormSection>
          <FormSectionHeader>
            <FormSectionHeaderTitle>Ерөнхий мэдээлэл</FormSectionHeaderTitle>
          </FormSectionHeader>
          <FormSectionContent>
            <Grid className="grid-cols-6 gap-6">
              <GridItem className="col-span-3">
                <TextField {...formState.name} onChange={handleChange} />
              </GridItem>

              <GridItem className="col-span-3">
                <TextField {...formState.foreignName} onChange={handleChange} />
              </GridItem>

              <GridItem className="col-span-6">
                <TextFieldWithVerification
                  {...formState.email}
                  onChange={handleChange}
                />
              </GridItem>

              <GridItem className="col-span-6">
                <TextFieldWithVerification
                  {...formState.phoneNumber}
                  onChange={handleChange}
                />
              </GridItem>

              <GridItem className="col-span-6">
                <TextField {...formState.address} onChange={handleChange} />
              </GridItem>
            </Grid>
          </FormSectionContent>
        </FormSection>

        <Divider />

        <FormSection>
          <FormSectionHeader>
            <FormSectionHeaderTitle>Банкны мэдээлэл</FormSectionHeaderTitle>
            <FormSectionHeaderDescription>
              Та орлого хүлээн авах дансны мэдээллээ оруулна уу.
            </FormSectionHeaderDescription>
          </FormSectionHeader>
          <FormSectionContent>
            <Grid className="grid-cols-6 gap-6">
              <GridItem className="col-span-6">
                <Select {...formState.bankName} onChange={handleChange} />
              </GridItem>

              <GridItem className="col-span-6">
                <TextField
                  {...formState.accountHolderName}
                  onChange={handleChange}
                />
              </GridItem>

              <GridItem className="col-span-6">
                <TextField
                  {...formState.accountNumber}
                  onChange={handleChange}
                />
              </GridItem>
            </Grid>
          </FormSectionContent>
        </FormSection>

        <Divider />

        <FormSection>
          <FormSectionHeader>
            <FormSectionHeaderTitle>Татварын мэдээлэл</FormSectionHeaderTitle>
            {/* <FormSectionHeaderDescription>
              Та орлого хүлээн авах дансны мэдээллээ оруулна уу.
            </FormSectionHeaderDescription> */}
          </FormSectionHeader>
          <FormSectionContent>
            <Grid className="grid-cols-6 gap-6">
              <GridItem className="col-span-6">
                <TextField {...formState.taxNumber} onChange={handleChange} />
              </GridItem>
            </Grid>
          </FormSectionContent>
        </FormSection>

        <div className="mt-10 flex justify-end">
          <PrimaryButton type="submit" className="w-40 text-center">
            Хадгалах
          </PrimaryButton>
        </div>
      </Form>
    </Fragment>
  );
}

export default Profile;
