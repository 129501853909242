import PrimaryButton from "./PrimaryButton";
import { PlusCircleIcon } from "@heroicons/react/solid";
import classNames from "../../utils/classNames";

function AddButton(props) {
  const { className, ...other } = props;

  return (
    <PrimaryButton
      className={classNames("flex items-center justify-center", className)}
      {...other}
    >
      <PlusCircleIcon className="h-5 w-5 mr-2" aria-hidden="true" />
      <span>Нэмэх</span>
    </PrimaryButton>
  );
}

export default AddButton;
