import React from "react";
import Label from "../Label";
import Input from "../Input";
import Error from "../Error";

function ClickableCheckBox(props) {
  const { label, value, ...other } = props;
  const { error } = other;

  return (
    <>
      <Label>
        <div className="flex items-start">
          <div className="flex items-center h-5">
            <Input
              type="checkbox"
              className="h-4 w-4 rounded"
              checked={value}
              {...other}
            />
          </div>
          <div className="ml-3">{label}</div>
        </div>
      </Label>
      <Error>{error}</Error>
    </>
  );
}

export default ClickableCheckBox;
