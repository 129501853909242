import React from "react";

function Main({ children }) {
  return (
    <main
      className="p-10 min-w-0 w-full flex-auto 
                lg:static lg:max-h-full lg:overflow-visible
                "
    >
      {children}
    </main>
  );
}

export default Main;
