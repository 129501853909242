import React from "react";

function Button(props) {
  const { className, children, ...other } = props;
  const Element = !!other.href ? "a" : "button";

  return (
    <Element
      className={`px-4 py-3 rounded-lg text-base select-none ${
        className || ""
      }`}
      type="button"
      {...other}
    >
      {children}
    </Element>
  );
}

export default Button;
