import Button from "./Button";

function WarningButton({ className, children, ...other }) {
  return (
    <Button
      className={`bg-red-600 hover:bg-red-800 text-white ${className || ""}`}
      {...other}
    >
      {children}
    </Button>
  );
}

export default WarningButton;
