import React from "react";
import Label from "../Label";
import Error from "../Error";
import Option from "./Option";

function Select(props) {
  const { label, validationRules, className, items, error, ...other } = props;
  const isValid = !!error;

  return (
    <>
      <Label>{label}</Label>
      <select
        className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none ${
          isValid
            ? "focus:ring-red-500 focus:border-red-500 border-red-500"
            : "focus:ring-blue-500 focus:border-blue-500"
        } ${className || ""}`}
        {...other}
      >
        <Option disabled value="">
          Сонгоно уу
        </Option>
        {items.map(({ label, value }) => (
          <Option key={value} value={value}>
            {label || value}
          </Option>
        ))}
      </select>
      <Error>{error}</Error>
    </>
  );
}

export default Select;
