import React from "react";
import { Menu as HeadlessUIMenu, Transition } from "@headlessui/react";
import {
  DotsHorizontalIcon,
  PencilIcon,
  LightningBoltIcon,
  ReceiptTaxIcon,
} from "@heroicons/react/solid";

function Menu() {
  return (
    <HeadlessUIMenu as="div" className="relative inline-block text-left">
      <div>
        <HeadlessUIMenu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          <DotsHorizontalIcon className="h-5 w-5" />
        </HeadlessUIMenu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <HeadlessUIMenu.Items className="absolute right-0 w-40 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
          <div className="p-1">
            <HeadlessUIMenu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  <PencilIcon
                    className={`h-5 w-5 ${
                      !!active ? "text-white" : "text-blue-500"
                    } mr-2`}
                  />
                  Засах
                </button>
              )}
            </HeadlessUIMenu.Item>
            <HeadlessUIMenu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  <LightningBoltIcon
                    className={`h-5 w-5 ${
                      !!active ? "text-white" : "text-red-500"
                    } mr-2`}
                  />
                  Цэнэг
                </button>
              )}
            </HeadlessUIMenu.Item>
            <HeadlessUIMenu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  <ReceiptTaxIcon
                    className={`h-5 w-5 ${
                      !!active ? "text-white" : "text-yellow-500"
                    } mr-2`}
                  />
                  Хямдруулах
                </button>
              )}
            </HeadlessUIMenu.Item>
          </div>
        </HeadlessUIMenu.Items>
      </Transition>
    </HeadlessUIMenu>
  );
}

export default Menu;
