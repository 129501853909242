import {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MailIcon } from "@heroicons/react/solid";
import CodeField from "../CodeField";
import { Grid, GridItem } from "../Grid";
import { useVerificationCode } from "../../hooks";
import { requiredRule } from "../../utils/inputValidationRules";

const initialVerificationCodeState = {
  data: {
    name: "verification_code",
    digits: [],
    value: "",
    validationRules: [requiredRule()],
    error: "",
  },
  length: 4,
};

function VerificationCodeModal({ label, value }, ref) {
  const { formState, handleChange, handleSubmit, digitsRef } =
    useVerificationCode(initialVerificationCodeState);

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setOpen(true);
    },
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
              <div className="text-center p-6 pb-4">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-14 w-14 rounded-full bg-blue-100">
                  <MailIcon
                    className="h-8 w-8 text-blue-600"
                    aria-hidden="true"
                  />
                </div>

                <Dialog.Title
                  as="h3"
                  className="text-xl leading-6 font-medium text-gray-900"
                >
                  {label} баталгаажуулалт
                </Dialog.Title>

                <div className="mt-4">
                  <p className="text-sm text-gray-500">
                    Таны <b>{value}</b> {label.toLowerCase()}т очсон
                    баталгаажуулах кодыг оруулна уу?
                  </p>
                </div>

                <Grid className="grid-cols-4 gap-4 px-20">
                  {formState.data.digits.map((digit, key) => (
                    <GridItem className="col-span-1" key={key}>
                      <CodeField
                        {...digit}
                        onChange={handleChange}
                        ref={digitsRef}
                      />
                    </GridItem>
                  ))}
                </Grid>
              </div>

              <div className="bg-gray-200 px-4 py-3 sm:px-6 sm:flex sm:items-center sm:justify-center">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto"
                  onClick={(e) => {
                    handleSubmit(e);
                    setOpen(false);
                  }}
                >
                  Баталгаажуулах
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default forwardRef(VerificationCodeModal);
