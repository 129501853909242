import React from "react";

function Label(props) {
  const { className, ...other } = props;

  return (
    <label
      className={`block text-sm font-medium text-gray-700 ${className || ''}`}
      {...other}
    >
      {props.children}
    </label>
  );
}

export default Label;
