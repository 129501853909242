import Label from "../Label";
import Input from "../Input";
import Error from "../Error";
import { VerificationCodeModal } from "../Modal";
import { useRef } from "react";

function TextFieldWithVerification(props) {
  const { label, isVerified, initialValue, ...other } = props;
  const error = other.error;
  const modalRef = useRef();

  return (
    <>
      <VerificationCodeModal ref={modalRef} {...props} />
      <Label>{label}</Label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <Input
          type="text"
          className="flex-1 block w-full rounded-none rounded-l-md relative"
          {...other}
        />
        <div className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 ">
          {isVerified ? (
            <span className="text-gray-500 text-sm select-none">
              Баталгаажсан
            </span>
          ) : (
            <button
              type="button"
              className=" text-blue-500 text-sm"
              onClick={() => {
                modalRef.current.openModal();
              }}
            >
              Баталгаажуулах
            </button>
          )}
        </div>
      </div>
      <Error>{error}</Error>
    </>
  );
}

export default TextFieldWithVerification;
