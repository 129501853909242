import React from "react";
import PublishingStep1 from "./PublishingStep1";
import PublishingStep2 from "./PublishingStep2";
import PublishingStep3 from "./PublishingStep3";
import PublishingStep4 from "./PublishingStep4";

const components = [
  PublishingStep1,
  PublishingStep2,
  PublishingStep3,
  PublishingStep4,
];

function PublishingStep(props) {
  const { activeStep, ...other } = props;
  const Component = components[activeStep - 1];

  return <Component {...other} />;
}

export default PublishingStep;
