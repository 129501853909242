import MainPage from "../pages/MainPage";
import SalesTable from "../pages/SalesTable";
import IncomeTable from "../pages/IncomeTable";
import BookTable from "../pages/BookTable";
import Profile from "../pages/Profile";
import PublishingPage from "../pages/PublishingPage";

const routes = [
  {
    path: "/",
    component: MainPage,
  },
  {
    path: "/sales",
    component: SalesTable,
  },
  {
    path: "/income",
    component: IncomeTable,
  },
  {
    path: "/books",
    component: BookTable,
  },
  {
    path: "/account",
    component: Profile,
  },
  {
    path: "/publish",
    component: PublishingPage,
  },
];

export default routes;
