import React, { Fragment } from "react";
import {
  CheckBoxGroup,
  Divider,
  TextField,
  Select,
  TextArea,
  ClickableCheckBox,
  RadioGroup,
  AddButton,
  RemoveButton,
  SearchField,
} from "../../../../components";
import {
  Form,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
  FormSectionHeaderDescription,
  FormSectionHeaderTitle,
} from "../../../../components/Form";
import { Grid, GridItem } from "../../../../components/Grid";
import { contributor, keyword, author } from "./initialFormState1";

function PublishingStep1({
  formState,
  handleChange,
  handleRemove,
  handleAdd,
  handleSearch,
}) {
  const is_translated = formState.is_translated.value;
  const is_series = formState.is_series.value;
  const is_edition = formState.is_edition.value;

  return (
    <Form>
      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Номын формат</FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <CheckBoxGroup {...formState.bookFormat} onChange={handleChange} />
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Бүтээлийн мэдээлэл</FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            <GridItem className="col-span-6">
              <TextField {...formState.title} onChange={handleChange} />
            </GridItem>

            <GridItem className="col-span-6">
              <TextField {...formState.sub_title} onChange={handleChange} />
            </GridItem>

            <GridItem className="col-span-6">
              <Select
                {...formState.original_language}
                onChange={handleChange}
              />
            </GridItem>

            <GridItem className="col-span-6">
              <ClickableCheckBox
                {...formState.is_translated}
                onChange={handleChange}
              />
            </GridItem>

            {is_translated && (
              <GridItem className="col-span-6">
                <TextField
                  {...formState.foreign_title}
                  onChange={handleChange}
                />
              </GridItem>
            )}
          </Grid>
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Цуврал</FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            <GridItem className="col-span-6">
              <ClickableCheckBox
                {...formState.is_series}
                onChange={handleChange}
              />
            </GridItem>

            {is_series && (
              <>
                <GridItem className="col-span-6">
                  <TextField
                    {...formState.series_title}
                    onChange={handleChange}
                  />
                </GridItem>

                <GridItem className="col-span-6">
                  <TextField
                    {...formState.series_number}
                    onChange={handleChange}
                  />
                </GridItem>
              </>
            )}
          </Grid>
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Хэвлэлийн дугаарлал</FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            <GridItem className="col-span-6">
              <ClickableCheckBox
                {...formState.is_edition}
                onChange={handleChange}
              />
            </GridItem>

            {is_edition && (
              <GridItem className="col-span-6">
                <TextField
                  {...formState.edition_number}
                  onChange={handleChange}
                />
              </GridItem>
            )}
          </Grid>
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Зохиогч</FormSectionHeaderTitle>
          <FormSectionHeaderDescription>
            Анхдагч зохиогч /автор/
          </FormSectionHeaderDescription>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            {formState.new_authors.length === 0 && (
              <Fragment>
                <GridItem className="col-span-6">
                  <SearchField
                    {...formState.author_id}
                    onChange={handleChange}
                    onSearch={handleSearch}
                  />
                </GridItem>

                <GridItem className="col-span-6 flex justify-center">
                  <AddButton
                    onClick={() =>
                      handleAdd({
                        key: "new_authors",
                        formField: { ...author },
                      })
                    }
                  />
                </GridItem>
              </Fragment>
            )}

            {formState.new_authors.map((new_author, idx) => (
              <Fragment key={idx}>
                <GridItem className="col-span-6 md:col-span-3">
                  <TextField
                    {...new_author.last_name}
                    onChange={(e) =>
                      handleChange(e, {
                        key: "new_authors",
                        idx,
                      })
                    }
                  />
                </GridItem>

                <GridItem className="col-span-6 md:col-span-3">
                  <TextField
                    {...new_author.first_name}
                    onChange={(e) =>
                      handleChange(e, {
                        key: "new_authors",
                        idx,
                      })
                    }
                  />
                </GridItem>

                <GridItem className="col-span-6 flex justify-end">
                  <RemoveButton
                    className="flex items-center justify-center"
                    onClick={() =>
                      handleRemove({
                        key: "new_authors",
                        idx,
                      })
                    }
                  />
                </GridItem>
              </Fragment>
            ))}
          </Grid>
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>
            Эрхлэн гаргахад оролцсон
          </FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            {formState.contributors.map((contributor, idx) => (
              <Fragment key={idx}>
                <GridItem className="col-span-6">
                  <Select
                    {...contributor.type}
                    onChange={(e) =>
                      handleChange(e, {
                        key: "contributors",
                        idx,
                      })
                    }
                  />
                </GridItem>
                <GridItem className="col-span-6 md:col-span-3">
                  <TextField
                    {...contributor.last_name}
                    onChange={(e) =>
                      handleChange(e, {
                        key: "contributors",
                        idx,
                      })
                    }
                  />
                </GridItem>
                <GridItem className="col-span-6 md:col-span-3">
                  <TextField
                    {...contributor.first_name}
                    onChange={(e) =>
                      handleChange(e, {
                        key: "contributors",
                        idx,
                      })
                    }
                  />
                </GridItem>
                <GridItem className="col-span-6 flex justify-end">
                  <RemoveButton
                    className="flex items-center justify-center"
                    onClick={() =>
                      handleRemove({
                        key: "contributors",
                        idx,
                      })
                    }
                  />
                </GridItem>
              </Fragment>
            ))}

            {formState.contributors.length < 10 && (
              <GridItem className="col-span-6 flex justify-center">
                <AddButton
                  onClick={() =>
                    handleAdd({
                      key: "contributors",
                      formField: { ...contributor },
                    })
                  }
                />
              </GridItem>
            )}
          </Grid>
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>
            Дүрслэл, товч тодорхойлолт
          </FormSectionHeaderTitle>
          <FormSectionHeaderDescription>
            Шүүмжлэл болон сэтгэгдэл
          </FormSectionHeaderDescription>
          <FormSectionHeaderDescription>
            Энэ хэсэг ном бүтээлийн дэлгэрэнгүй хуудас дээр харагдана.
          </FormSectionHeaderDescription>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            <GridItem className="col-span-6">
              <TextField {...formState.critic} onChange={handleChange} />
            </GridItem>

            <GridItem className="col-span-6">
              <TextArea {...formState.review} onChange={handleChange} />
            </GridItem>
          </Grid>
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Хэвлэн нийтлэх эрх</FormSectionHeaderTitle>
          <FormSectionHeaderDescription>
            Аль нэгийг нь сонгох
          </FormSectionHeaderDescription>
        </FormSectionHeader>
        <FormSectionContent>
          <RadioGroup
            verticalOnly
            {...formState.right}
            onChange={handleChange}
          />
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Түлхүүр үг</FormSectionHeaderTitle>
          <FormSectionHeaderDescription>
            Таны ном бүтээлийг төлөөлж болох 7 түлхүүр үгийг оруулна уу. Энэ нь
            хайлт илэрцэд туслах үүрэгтэй.
          </FormSectionHeaderDescription>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            {formState.keywords.map(({ keyword }, idx) => {
              return (
                <Fragment key={idx}>
                  <GridItem className="col-span-6">
                    <TextField
                      {...keyword}
                      onChange={(e) =>
                        handleChange(e, {
                          key: "keywords",
                          idx,
                        })
                      }
                    />
                  </GridItem>
                  <GridItem className="col-span-6 flex justify-end">
                    <RemoveButton
                      onClick={() =>
                        handleRemove({
                          key: "keywords",
                          idx,
                        })
                      }
                    />
                  </GridItem>
                </Fragment>
              );
            })}

            {formState.keywords.length < 7 && (
              <GridItem className="col-span-6 flex justify-center">
                <AddButton
                  onClick={() =>
                    handleAdd({
                      key: "keywords",
                      formField: { ...keyword },
                    })
                  }
                />
              </GridItem>
            )}
          </Grid>
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Насны хамрах хүрээ</FormSectionHeaderTitle>
          <FormSectionHeaderDescription>
            Хэрэв тухайн ном бүтээл нь хүүхдэд зориулсан бүтээл бол насны доод
            болон дээд хамрах хүрээг заах, Насанд хүрэгчдэд зориулсан ном бүтээл
            бол насны доод хязгаарыг заах
          </FormSectionHeaderDescription>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            <GridItem className="col-span-6">
              <Select
                {...formState.restriction_min_age}
                onChange={handleChange}
              />
            </GridItem>

            <GridItem className="col-span-6">
              <Select
                {...formState.restriction_max_age}
                onChange={handleChange}
              />
            </GridItem>
          </Grid>
        </FormSectionContent>
      </FormSection>
    </Form>
  );
}

export default PublishingStep1;
