export const GB = 1000 * 1000 * 1000;
export const MB = 1000 * 1000;
export const KB = 1000;

export function getFileName(fileName) {
  if (fileName.length <= 50) return fileName;
  else return fileName.slice(0, 50) + "...";
}

export function getFileSize(fileSize) {
  if (fileSize < KB) return `${fileSize} Bytes`;
  else if (KB <= fileSize && fileSize < MB)
    return `${(fileSize / KB).toFixed(1)} KB`;
  else if (MB <= fileSize && fileSize < GB)
    return `${(fileSize / MB).toFixed(1)} MB`;
  else return "";
}

export function getFileInfo(file) {
  if (!file || !file.name || file.size === undefined || file.size === null)
    return {
      fileName: "",
      fileSize: "",
    };
  else
    return {
      fileName: getFileName(file.name),
      fileSize: getFileSize(file.size),
    };
}

export function isFile(inputElementName) {
  return document.getElementsByName(inputElementName)[0].type === "file";
}
