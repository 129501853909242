import React from "react";

function NavBarHeader({ children }) {
  return (
    <h5 className="font-bold text-lg px-3 my-3 flex items-center">
      {children}
    </h5>
  );
}

export default NavBarHeader;
