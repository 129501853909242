import React from "react";
import Label from "../Label";
import Input from "../Input";

function CheckBox(props) {
  const { label, ...other } = props;
  const { value } = other;

  return (
    <Label>
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <Input type="checkbox" className="h-4 w-4 rounded" {...other} />
        </div>
        <div className="ml-3">{label || value}</div>
      </div>
    </Label>
  );
}

export default CheckBox;
