import React from "react";
import NavBar from "../NavBar";
import Header from "../Header";
import Main from "../Main";

const publishers = [
  {
    id: 1,
    name: "Wade Cooper",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 2,
    name: "Arlene Mccoy",
    avatar:
      "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 3,
    name: "Devon Webb",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
];

function Layout({ children }) {
  return (
    <React.Fragment>
      <Header publishers={publishers} />
      <div className="w-full max-w-5xl xl:max-w-6xl mx-auto">
        <div className="flex">
          <NavBar />
          <Main>{children}</Main>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Layout;
