import React, { Fragment, useState } from "react";
import {
  Section,
  SectionContent,
  SectionHeader,
  SectionHeaderTitle,
} from "../components/Section";

import Divider from "../components/Divider";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
  CardDescription,
} from "../components/Card";
import { Avatar, AvatarGroup } from "../components/Avatar";
import SalesChart from "../components/Chart";

const images = [
  {
    id: 1,
    src: "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?imdib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 2,
    src: "https://images.unsplash.com/photo-1550525811-e5869dd03032?imdib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 3,
    src: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?imdib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
  {
    id: 4,
    src: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?imdib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

function MainPage() {
  const [numberOfFollowers] = useState(456);

  return (
    <Fragment>
      <Section>
        <SectionHeader className="flex flex-col sm:flex-row justify-between space-y-0">
          <SectionHeaderTitle>Тойм</SectionHeaderTitle>
          <div className="flex space-x-5">
            <div>
              <span className="text-sm text-gray-600">{`Дагагчид: `}</span>
              <span className="text-black text-md font-bold">
                {numberOfFollowers}
              </span>
            </div>
            <AvatarGroup>
              {images.map((image) => (
                <Avatar key={image.id} {...image} />
              ))}
            </AvatarGroup>
          </div>
        </SectionHeader>
      </Section>

      <Divider />

      <Section className="flex flex-col space-y-5 items-center md:items-stretch">
        <SectionContent className="space-y-5 md:flex md:justify-between md:space-x-5 md:space-y-0">
          <Card>
            <CardHeader>
              <CardDescription>13</CardDescription>
              <CardTitle>Нийт бүтээл</CardTitle>
            </CardHeader>

            <div className="flex justify-between">
              <CardContent>
                <CardDescription>12</CardDescription>
                <CardTitle>Цахим ном</CardTitle>
              </CardContent>

              <CardContent>
                <CardDescription>1</CardDescription>
                <CardTitle>Аудио ном</CardTitle>
              </CardContent>
            </div>
          </Card>

          <Card>
            <CardHeader>
              <CardDescription>96</CardDescription>
              <CardTitle>Борлуулалтын тоо (нийт)</CardTitle>
            </CardHeader>

            <div className="flex justify-between">
              <CardContent>
                <CardDescription>26</CardDescription>
                <CardTitle>Энэ онд</CardTitle>
              </CardContent>

              <CardContent>
                <CardDescription>12</CardDescription>
                <CardTitle>Энэ сард</CardTitle>
              </CardContent>
            </div>
          </Card>

          <Card>
            <CardHeader>
              <CardDescription>436,982 ₮</CardDescription>
              <CardTitle>Нийт орлого</CardTitle>
            </CardHeader>

            <div className="flex justify-between">
              <CardContent>
                <CardDescription>432,497</CardDescription>
                <CardTitle>Олгогдсон</CardTitle>
              </CardContent>

              <CardContent>
                <CardDescription>4,485</CardDescription>
                <CardTitle>Олгогдох</CardTitle>
              </CardContent>
            </div>
          </Card>
        </SectionContent>

        <SectionContent className="space-y-5 md:flex md:justify-between md:space-x-5 md:space-y-0">
          <Card>
            <CardContent>
              <CardDescription>65461</CardDescription>
              <CardTitle>Бүтээл сонирхсон тоо (нийт)</CardTitle>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <CardDescription>62</CardDescription>
              <CardTitle>Өдөр борлуулалттай</CardTitle>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <CardDescription>23</CardDescription>
              <CardTitle>Сэтгэгдэл</CardTitle>
            </CardContent>
          </Card>
        </SectionContent>
      </Section>

      <Divider />

      <Section>
        <SectionContent>
          <Card className="w-full hidden sm:block">
            <CardContent>
              <SalesChart />
            </CardContent>
          </Card>
        </SectionContent>
      </Section>
    </Fragment>
  );
}

export default MainPage;
