import React from "react";
import CheckBox from "./CheckBox";
import Error from "../Error";

function CheckBoxGroup(props) {
  const { items, value: currentItems, ...other } = props;
  const { error } = other;

  return (
    <>
      <div className="mt-1 flex flex-col space-y-2 md:flex-row md:space-x-4 md:space-y-0">
        {items.map((item, idx) => (
          <CheckBox
            key={idx}
            {...item}
            {...other}
            checked={currentItems.includes(item.value)}
          />
        ))}
      </div>
      <Error>{error}</Error>
    </>
  );
}

export default CheckBoxGroup;
