import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "1  сар",
    uv: 1000,
    amt: 2400,
  },
  {
    name: "2  сар",
    uv: 2000,
    amt: 2400,
  },
  {
    name: "3  сар",
    uv: 4000,
    amt: 2400,
  },
  {
    name: "4  сар",
    uv: 3000,
    amt: 2400,
  },
  {
    name: "5  сар",
    uv: 4000,
    amt: 2400,
  },
  {
    name: "6  сар",
    uv: 4000,
    amt: 2400,
  },
  {
    name: "7  сар",
    uv: 9000,
    amt: 2400,
  },
  {
    name: "8  сар",
    uv: 10000,
    amt: 2400,
  },
  {
    name: "9  сар",
    uv: 2000,
    amt: 2400,
  },
  {
    name: "10  сар",
    uv: 5000,
    amt: 2400,
  },
  {
    name: "11  сар",
    uv: 16000,
    amt: 2400,
  },
  {
    name: "12  сар",
    uv: 6000,
    amt: 2400,
  },
];

function SalesChart() {
  return (
    <ResponsiveContainer width="100%" aspect={2}>
      <AreaChart
        width={700}
        height={350}
        data={data}
        margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.6} />
            <stop offset="95%" stopColor="#3B82F6" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend verticalAlign="top" height={56} iconType="circle" />
        <Area
          name="Сонсолт"
          dataKey="uv"
          stroke="#3B82F6"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default SalesChart;
