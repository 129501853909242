import WarningButton from "./WarningButton";
import { TrashIcon } from "@heroicons/react/solid";
import classNames from "../../utils/classNames";

function RemoveButton(props) {
  const { className, ...other } = props;

  return (
    <WarningButton
      className={classNames("flex items-center justify-center", className)}
      {...other}
    >
      <TrashIcon className="h-5 w-5 mr-2" aria-hidden="true" />
      <span>Устгах</span>
    </WarningButton>
  );
}

export default RemoveButton;
