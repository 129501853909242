import React from "react";
import Label from "../Label";
import Input from "../Input";
import Error from "../Error";
import FileInputWrapper from "./FileInputWrapper";

function FileInput(props) {
  const { label, value, ...other } = props;
  const error = other.error;

  return (
    <>
      <Label>{label}</Label>
      <FileInputWrapper file={value} error={error}>
        <Input
          type="file"
          className="h-full w-full opacity-0 cursor-pointer"
          {...other}
        />
      </FileInputWrapper>
      <Error>{error}</Error>
    </>
  );
}

export default FileInput;
