import React from "react";
import { Divider, FileInput } from "../../../../components";
import {
  Form,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
  FormSectionHeaderTitle,
} from "../../../../components/Form";
import { Grid, GridItem } from "../../../../components/Grid";

const ebookItems = ["ebookFile", "ebookCover"];

const audiobookItems = [
  "audiobookFile",
  "audiobookIntro",
  "audiobookForeword",
  "audiobookChapter1",
  "audiobookChapter2",
  "audiobookChapter3",
  "audiobookAnnex",
  "audiobookCover",
  "audiobookSampleFile",
];

function PublishingStep3({ formState, handleChange }) {
  return (
    <Form>
      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Цахим ном</FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            {ebookItems.map((ebookItem, key) => (
              <GridItem className="col-span-6" key={key}>
                <FileInput {...formState[ebookItem]} onChange={handleChange} />
              </GridItem>
            ))}
          </Grid>
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Аудио ном</FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            {audiobookItems.map((audiobookItem, key) => (
              <GridItem className="col-span-6" key={key}>
                <FileInput
                  {...formState[audiobookItem]}
                  onChange={handleChange}
                />
              </GridItem>
            ))}
          </Grid>
        </FormSectionContent>
      </FormSection>
    </Form>
  );
}

export default PublishingStep3;
