import React from "react";

function Form({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} noValidate>
      {children}
    </form>
  );
}

export default Form;
