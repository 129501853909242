import React, { Children } from "react";
import CardTitle from "./CardTitle";
import CardDescription from "./CardDescription";

function CardContent({ children }) {
  const childrenArray = Children.toArray(children).filter(Boolean);

  const cardChildren = childrenArray.map((child) => {
    if (React.isValidElement(child)) {
      let className = "";

      if (child.type === CardDescription) className = "font-bold text-lg";

      if (child.type === CardTitle) className = "text-sm text-gray-600";

      return React.cloneElement(child, { className });
    }

    return child;
  });

  return <div>{cardChildren}</div>;
}

export default CardContent;
