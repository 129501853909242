import React, { Fragment } from "react";
import Divider from "../../components/Divider";
import PublishingStepper from "./PublishingStepper";
import PublishingStep, { initialFormStates } from "./PublishingStep";
import PublishingActions from "./PublishingActions";
import { useMultiStepForm } from "../../hooks";

function PublishingPage() {
  const {
    formStates,
    activeStep,
    lastStep,
    handleChange,
    handleGoForward,
    handleGoBack,
    handleGoTo,
    handleAdd,
    handleRemove,
    handleSearch,
  } = useMultiStepForm(initialFormStates);
  const formState = formStates[activeStep];

  return (
    <Fragment>
      <PublishingStepper
        activeStep={activeStep}
        lastStep={lastStep}
        handleGoTo={handleGoTo}
      />

      <Divider />

      <PublishingStep
        activeStep={activeStep}
        formState={formState}
        handleChange={handleChange}
        handleAdd={handleAdd}
        handleRemove={handleRemove}
        handleSearch={handleSearch}
      />

      <PublishingActions
        activeStep={activeStep}
        handleGoForward={handleGoForward}
        handleGoBack={handleGoBack}
      />
    </Fragment>
  );
}

export default PublishingPage;
