import React from "react";
import Radio from "./Radio";
import Error from "../Error";

function RadioGroup(props) {
  const { items, value: currentValue, verticalOnly, ...other } = props;
  const { error } = other;
  const className = `mt-1 flex flex-col space-y-2 ${
    !verticalOnly ? "md:flex-row md:space-x-4 md:space-y-0" : ""
  }`;

  return (
    <>
      <div className={className}>
        {items.map((item, idx) => (
          <Radio
            key={idx}
            checked={item.value === currentValue}
            {...item}
            {...other}
          />
        ))}
      </div>
      <Error>{error}</Error>
    </>
  );
}

export default RadioGroup;
