import { useCallback, useEffect, useReducer } from "react";

const reducer = (stepState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "GO_FORWARD":
      const { MAX } = payload;

      return {
        ...stepState,
        activeStep: Math.min(stepState.activeStep + 1, MAX),
        lastStep: Math.max(stepState.lastStep, stepState.activeStep + 1),
      };
    case "GO_BACK":
      const { MIN } = payload;

      return {
        ...stepState,
        activeStep: Math.max(stepState.activeStep - 1, MIN),
      };
    case "GO_TO":
      const { newStep } = payload;

      return {
        ...stepState,
        activeStep: newStep,
      };
    default:
      return {
        ...stepState,
      };
  }
};

const initialize = (initialStep) => {
  return {
    activeStep: initialStep,
    lastStep: initialStep,
  };
};

function useStep({ initialStep = 1, numberOfSteps = 1 }) {
  const [stepState, dispatch] = useReducer(reducer, initialize(initialStep));
  const { activeStep, lastStep } = stepState;
  const MAX = numberOfSteps;
  const MIN = 1;

  const goForward = useCallback(() => {
    dispatch({ type: "GO_FORWARD", payload: { MAX } });
  }, [MAX]);

  const goBack = useCallback(() => {
    dispatch({ type: "GO_BACK", payload: { MIN } });
  }, [MIN]);

  const goTo = useCallback(
    (newStep) => {
      if (MIN <= newStep && newStep <= lastStep) {
        dispatch({ type: "GO_TO", payload: { newStep } });
      }
    },
    [MIN, lastStep]
  );

  const isLastStep = useCallback(() => {
    return activeStep === lastStep;
  }, [activeStep, lastStep]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return { activeStep, lastStep, isLastStep, goForward, goBack, goTo };
}

export default useStep;
