import React from "react";
import { LinkButton } from "../Button";
import NavBarSection from "./NavBarSection";
import NavBarHeader from "./NavBarHeader";
import NavBarDescription from "./NavBarDescription";
import NavBarItem from "./NavBarItem";
import { PlusCircleIcon } from "@heroicons/react/outline";

const mainItems = [
  {
    title: "Тойм",
    to: "/",
  },
  {
    title: "Борлуулалт",
    to: "/sales",
  },
  {
    title: "Орлого ололт",
    to: "/income",
  },
  {
    title: "Сэтгэгдэл",
    to: "/comments",
  },
];

const publicationItems = [
  {
    title: "Нийтэлсэн бүтээлийн жагсаалт",
    to: "/books",
  },
];

const settingsItems = [
  {
    title: "Нийтлэгчийн бүртгэл",
    to: "/account",
  },
];

const otherItems = [
  {
    title: "Мэдэгдэл",
    to: "#",
  },
  {
    title: "Тохиргоо",
    to: "#",
  },
  {
    title: "Тусламжийн хүсэлт",
    to: "#",
  },
  {
    title: "Гарах",
    to: "#",
  },
];

function NavBar() {
  return (
    <aside
      className="fixed z-10 inset-0 flex-none h-full 
                  w-full  
                  lg:static lg:h-auto lg:overflow-y-visible 
                  lg:pt-0 lg:w-56 xl:w-64 lg:block hidden
                  "
    >
      <nav className="p-4">
        <NavBarSection>
          <NavBarHeader>Тайлан</NavBarHeader>
          <NavBarDescription>
            {mainItems.map((mainItem, idx) => {
              return <NavBarItem key={idx} {...mainItem} />;
            })}
          </NavBarDescription>
        </NavBarSection>

        <NavBarSection>
          <NavBarHeader>Бүтээлийн сан</NavBarHeader>
          <NavBarDescription>
            {publicationItems.map((publicationItem, idx) => {
              return <NavBarItem key={idx} {...publicationItem} />;
            })}
          </NavBarDescription>
        </NavBarSection>

        <LinkButton
          className="ml-3 mt-10 mb-3 flex items-center"
          href="/publish"
        >
          <PlusCircleIcon className="h-5 w-5 text-white" />
          <div className="ml-3">Бүтээл нийтлэх</div>
        </LinkButton>

        <NavBarSection>
          <NavBarHeader>Бүртгэл</NavBarHeader>
          <NavBarDescription>
            {settingsItems.map((settingsItem, idx) => {
              return <NavBarItem key={idx} {...settingsItem} />;
            })}
          </NavBarDescription>
        </NavBarSection>

        <NavBarSection>
          <NavBarHeader>Бусад</NavBarHeader>
          <NavBarDescription>
            {otherItems.map((otherItem, idx) => {
              return <NavBarItem key={idx} {...otherItem} />;
            })}
          </NavBarDescription>
        </NavBarSection>
      </nav>
    </aside>
  );
}

export default NavBar;
