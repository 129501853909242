import React from "react";
import { Divider, Select } from "../../../../components";
import {
  Form,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
  FormSectionHeaderTitle,
} from "../../../../components/Form";
import { Grid, GridItem } from "../../../../components/Grid";

function PublishingStep2({ formState, handleChange }) {
  return (
    <Form>
      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Бүтээлийн ангилал</FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            <GridItem className="col-span-6">
              <Select {...formState.genre} onChange={handleChange} />
            </GridItem>

            <GridItem className="col-span-6">
              <Select {...formState.category} onChange={handleChange} />
            </GridItem>
          </Grid>
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>Бүтээлийн дэд ангилал</FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            <GridItem className="col-span-6">
              <Select {...formState.subgenre} onChange={handleChange} />
            </GridItem>

            <GridItem className="col-span-6">
              <Select {...formState.subcategory} onChange={handleChange} />
            </GridItem>
          </Grid>
        </FormSectionContent>
      </FormSection>
    </Form>
  );
}

export default PublishingStep2;
