import React from "react";
import { Divider, TextArea, TextField } from "../../../../components";
import {
  Form,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
  FormSectionHeaderTitle,
} from "../../../../components/Form";
import { Grid, GridItem } from "../../../../components/Grid";

function PublishingStep4({ formState, handleChange }) {
  return (
    <Form>
      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>
            Бүтээгдэхүүний үнэлгээ ба рояалти хувь
          </FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            <GridItem className="col-span-6">
              <TextField {...formState.listPrice} onChange={handleChange} />
            </GridItem>

            <GridItem className="col-span-6">
              <TextField {...formState.serviceFee} onChange={handleChange} />
            </GridItem>

            <GridItem className="col-span-6">
              <TextField {...formState.tax} onChange={handleChange} />
            </GridItem>

            <GridItem className="col-span-6">
              <TextField {...formState.calculation} onChange={handleChange} />
            </GridItem>
          </Grid>
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>M+ promotions</FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            <GridItem className="col-span-6">
              <TextArea {...formState.highlightPost} onChange={handleChange} />
            </GridItem>
          </Grid>
        </FormSectionContent>
      </FormSection>

      <Divider></Divider>

      <FormSection>
        <FormSectionHeader>
          <FormSectionHeaderTitle>
            Үйлчилгээний нөхцөл, гэрээ
          </FormSectionHeaderTitle>
        </FormSectionHeader>
        <FormSectionContent>
          <Grid className="grid-cols-6 gap-6">
            <GridItem className="col-span-6">
              <TextArea {...formState.termsAndConditions} />
            </GridItem>
          </Grid>
        </FormSectionContent>
      </FormSection>
    </Form>
  );
}

export default PublishingStep4;
