import React from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import NumberIcon from "./NumberIcon";

function Step({ children, isCompleted, isActive, number, onClick }) {
  if (isActive) {
    return (
      <div
        className="p-3 rounded-lg text-left 
      flex justify-center md:justify-start items-center 
      transition duration-200 ease-in-out 
      bg-blue-300  ring-4 ring-inset ring-blue-600 border-blue-600"
      >
        <div className="flex-shrink-0 h-14 w-14">
          <NumberIcon number={number} className="h-14 w-14 text-blue-600" />
        </div>
        <div className="ml-2 hidden md:block">{children}</div>
      </div>
    );
  } else {
    if (isCompleted)
      return (
        <div
          className="p-3 rounded-lg text-left 
        flex justify-center md:justify-start items-center 
        transition duration-200 ease-in-out 
        bg-green-100 transform hover:scale-110 cursor-pointer"
        onClick={onClick}
        >
          <div className="flex-shrink-0 h-14 w-14">
            <CheckCircleIcon className="h-14 w-14 text-green-500" />
          </div>
          <div className="ml-2 hidden md:block">{children}</div>
        </div>
      );
    else
      return (
        <div
          className="p-3 rounded-lg text-left 
        flex justify-center md:justify-start items-center 
        transition duration-200 ease-in-out 
        bg-gray-100 transform hover:scale-110 cursor-pointer"
        onClick={onClick}

        >
          <div className="flex-shrink-0 h-14 w-14">
            <NumberIcon number={number} className="h-14 w-14 text-gray-400" />
          </div>
          <div className="ml-2 hidden md:block">{children}</div>
        </div>
      );
  }
}

export default Step;
