import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
} from "../components/Table";

const salesList = [
  {
    invoice: "PRO-210702-24a4",
    contentName: "Хуучин байшин",
    type: "Аудио хувилбар",
    price: 35000,
    serviceFee: 10850,
    income: 24150,
    isPaid: true,
    date: "2021-08-11T03:55:17.290Z",
  },
  {
    invoice: "PRO-210702-24a4",
    contentName: "Хуучин байшин",
    type: "Аудио хувилбар",
    price: 35000,
    serviceFee: 10850,
    income: 24150,
    isPaid: true,
    date: "2021-08-11T03:55:17.290Z",
  },
  {
    invoice: "PRO-210702-24a4",
    contentName: "Хуучин байшин",
    type: "Аудио хувилбар",
    price: 35000,
    serviceFee: 10850,
    income: 24150,
    isPaid: true,
    date: "2021-08-11T03:55:17.290Z",
  },
  {
    invoice: "PRO-210702-24a4",
    contentName: "Хуучин байшин",
    type: "Аудио хувилбар",
    price: 35000,
    serviceFee: 10850,
    income: 24150,
    isPaid: true,
    date: "2021-08-11T03:55:17.290Z",
  },
  {
    invoice: "PRO-210702-24a4",
    contentName: "Хуучин байшин",
    type: "Аудио хувилбар",
    price: 35000,
    serviceFee: 10850,
    income: 24150,
    isPaid: true,
    date: "2021-08-11T03:55:17.290Z",
  },
  {
    invoice: "PRO-210702-24a4",
    contentName: "Хуучин байшин",
    type: "Аудио хувилбар",
    price: 35000,
    serviceFee: 10850,
    income: 24150,
    isPaid: true,
    date: "2021-08-11T03:55:17.290Z",
  },
  {
    invoice: "PRO-210702-24a4",
    contentName: "Хуучин байшин",
    type: "Аудио хувилбар",
    price: 35000,
    serviceFee: 10850,
    income: 24150,
    isPaid: false,
    date: "2021-08-11T03:55:17.290Z",
  },
  {
    invoice: "PRO-210702-24a4",
    contentName: "Хуучин байшин",
    type: "Аудио хувилбар",
    price: 35000,
    serviceFee: 10850,
    income: 24150,
    isPaid: true,
    date: "2021-08-11T03:55:17.290Z",
  },
];

function SalesTable() {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Нэхэмжлэх</TableHeaderCell>
          <TableHeaderCell>Бүтээл</TableHeaderCell>
          <TableHeaderCell>Төрөл</TableHeaderCell>
          <TableHeaderCell>Үнэ</TableHeaderCell>
          <TableHeaderCell>Үйлчилгээний шимтгэл</TableHeaderCell>
          <TableHeaderCell>Орлого</TableHeaderCell>
          <TableHeaderCell>Олголт</TableHeaderCell>
          <TableHeaderCell>Огноо</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {salesList.map((salesInfo, idx) => (
          <TableRow key={idx}>
            <TableCell className="text-sm">{salesInfo.invoice}</TableCell>
            <TableCell className="text-sm">{salesInfo.contentName}</TableCell>
            <TableCell className="text-sm text-gray-400">
              {salesInfo.type}
            </TableCell>
            <TableCell className="text-sm">{salesInfo.price}</TableCell>
            <TableCell className="text-sm">{salesInfo.serviceFee}</TableCell>
            <TableCell className="text-sm">{salesInfo.income}</TableCell>
            <TableCell className="text-sm flex justify-center">
              {salesInfo.isPaid ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="limegreen"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="red"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </TableCell>
            <TableCell className="text-sm">
              {new Date(salesInfo.date).toLocaleDateString()}{" "}
              {new Date(salesInfo.date).toLocaleTimeString()}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default SalesTable;
