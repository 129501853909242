import { initialFormState1 } from "./PublishingStep1";
import { initialFormState2 } from "./PublishingStep2";
import { initialFormState3 } from "./PublishingStep3";
import { initialFormState4 } from "./PublishingStep4";

const initialFormStates = {
  1: initialFormState1,
  2: initialFormState2,
  3: initialFormState3,
  4: initialFormState4,
};

export default initialFormStates;
