import { useEffect, useRef } from "react";

function useWindowEvent(type, listener, options) {
  const listenerRef = useRef(listener);
  listenerRef.current = listener;

  useEffect(() => {
    const handler = (event) => {
      listenerRef.current.call(window, event);
    };

    window.addEventListener(type, handler, options);

    return () => {
      window.removeEventListener(type, handler, options);
    };
  }, [type, options]);
}

export default useWindowEvent;
