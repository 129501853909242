import React from "react";

function TableHeaderCell({ className, children }) {
  return (
    <th
      scope="col"
      className={`px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider ${className}`}
    >
      {children}
    </th>
  );
}

export default TableHeaderCell;
