import { fileRequiredRule } from "../../../../utils/inputValidationRules";

const initialFormState3 = {
  //ebook
  ebookFile: {
    label: "Эх файл",
    name: "ebookFile",
    value: null,
    validationRules: [fileRequiredRule()],
    error: "",
    accept: ".doc,.docx,application/pdf,",
  },
  ebookCover: {
    label: "Хавтасны зураг",
    name: "ebookCover",
    value: null,
    validationRules: [fileRequiredRule()],
    error: "",
    accept: "image/*",
  },

  //Audiobook
  audiobookFile: {
    label: "Эх файл",
    name: "audiobookFile",
    value: null,
    validationRules: [],
    error: "",
    accept: "audio/*",
  },
  audiobookIntro: {
    label: "Эхлэл / танилцуулга",
    name: "audiobookIntro",
    value: null,
    validationRules: [],
    error: "",
    accept: "audio/*",
  },
  audiobookForeword: {
    label: "Өмнөх үг",
    name: "audiobookForeword",
    value: null,
    validationRules: [],
    error: "",
    accept: "audio/*",
  },
  audiobookChapter1: {
    label: "Бүлэг 1",
    name: "audiobookChapter1",
    value: null,
    validationRules: [],
    error: "",
    accept: "audio/*",
  },
  audiobookChapter2: {
    label: "Бүлэг 2",
    name: "audiobookChapter2",
    value: null,
    validationRules: [],
    error: "",
    accept: "audio/*",
  },
  audiobookChapter3: {
    label: "Бүлэг 3",
    name: "audiobookChapter3",
    value: null,
    validationRules: [],
    error: "",
    accept: "audio/*",
  },
  audiobookAnnex: {
    label: "Хавсралт / Төгсгөл",
    name: "audiobookAnnex",
    value: null,
    validationRules: [],
    error: "",
    accept: "audio/*",
  },
  audiobookCover: {
    label: "Хавтасны зураг",
    name: "audiobookCover",
    value: null,
    validationRules: [],
    error: "",
    accept: "image/*",
  },
  audiobookSampleFile: {
    label: "Хэсэгчлэн сонсох файл",
    name: "audiobookSampleFile",
    value: null,
    validationRules: [],
    error: "",
    accept: "audio/*",
  },
};

export default initialFormState3;
