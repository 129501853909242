import React from "react";
import Button from "./Button";

function PrimaryButton({ className, children, ...other }) {
  return (
    <Button
      className={`bg-blue-600 hover:bg-blue-800 text-white ${className || ""}`}
      {...other}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
