import { useCallback, useReducer } from "react";

const reducer = (formState, event) => {
  const { formField } = event;
  return { ...formState, [formField.name]: formField };
};

function useForm(initialFormState) {
  const [formState, dispatch] = useReducer(reducer, initialFormState);

  const validateFormField = useCallback(({ value, validationRules }) => {
    for (const rule of validationRules) {
      if (!rule.validate(value)) {
        return { isValid: false, error: rule.error };
      }
    }

    return { isValid: true, error: "" };
  }, []);

  const handleChange = useCallback(
    (event) => {
      const { name, type, value } = event.target;
      let formField = { ...formState[name] };

      switch (type) {
        case "checkbox":
          const { checked } = event.target;
          switch (typeof formField.value) {
            case "object":
              const items = formField.items.map((item) => {
                if (item.value === value) item.checked = checked;
                return item;
              });

              formField = {
                ...formField,
                items,
                value: items
                  .filter((item) => item.checked)
                  .map((item) => item.value),
              };
              break;
            case "boolean":
              formField = { ...formField, value: checked };
              break;
            default:
              break;
          }
          break;
        case "radio":
        case "select-one":
        case "textarea":
        default:
          formField = { ...formField, value };

          if (formField.hasOwnProperty("isVerified")) {
            formField = {
              ...formField,
              isVerified: formField.value === formField.initialValue,
            };
          }
          break;
      }

      const { error } = validateFormField(formField);
      formField = { ...formField, error };

      dispatch({ formField });
    },
    [validateFormField, formState]
  );

  const canSubmit = useCallback(
    (formState) => {
      let isFormValid = true;
      for (const name in formState) {
        if (Object.hasOwnProperty.call(formState, name)) {
          let formField = { ...formState[name] };
          const { isValid, error } = validateFormField(formField);
          isFormValid = isFormValid && isValid;
          formField = { ...formField, error };

          dispatch({ formField });
        }
      }
      return isFormValid;
    },
    [validateFormField]
  );

  const submitData = useCallback((formState) => {
    console.log("Submitting...");
    console.log(formState);
    console.log("Submitted");
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (canSubmit(formState)) {
        submitData(formState);
      }
    },
    [formState, canSubmit, submitData]
  );

  return [formState, handleChange, handleSubmit];
}

export default useForm;
