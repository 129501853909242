import React, { forwardRef } from "react";

function Input(props, ref) {
  const { className, validationRules, error, index, ...other } = props;
  const isValid = !!!error;
  const isTextArea = other.type === "textarea";
  const InputElement = isTextArea ? "textarea" : "input";

  return (
    <InputElement
      className={`border-gray-300 shadow-sm ${
        isValid
          ? "focus:ring-blue-500 focus:border-blue-500"
          : "focus:ring-red-500 focus:border-red-500 border-red-500"
      } ${className || ""}`}
      ref={(el) => {
        if (!!ref && !!ref.current && Array.isArray(ref.current))
          ref.current[index] = el;
      }}
      {...other}
    />
  );
}

export default forwardRef(Input);
