import { requiredRule } from "../../../../utils/inputValidationRules";

const categories = {
  Зохиол: [
    "Ерөнхий",
    "Тулалдаант болон адал явдалт",
    "Альтернатив түүх",
    "Түүвэр зохиол, антологи",
    "Намтар",
    "Христ",
    "Сонгодог",
    "Орчин үеийн эмэгтэй",
    "Гэмт хэрэг",
    "Соёлын өв",
    "Еротик",
    "Үлгэр, домог, ардын аман зохиол",
    "Гэр бүлийн амьдрал",
    "Фантастик",
    "Гей",
    "Сүнс",
    "Хэрцгий, бүдүүлэг",
    "Түүхэн",
    "Амралт, баяр",
    "Аймшиг",
    "Инээдмийн/ Хошин шог",
    "Хууль",
    "Лесби",
    "Утга зохиол",
    "Ид шидийн реализм",
    "Холимог",
    "Хэвлэл мэдээллээс сэдэвлэсэн",
    "Анагаах ухаан",
    "Нууцлаг болон эрэн сурвалжлах",
    "Ер бусын, ид шид",
    "Улс төр",
    "Сэтгэл зүйн",
    "Шашин шүтлэг",
    "Хайр/Романс",
    "Баатарлаг тууль",
    "Шог зохиол",
    "Шинжлэх ухааны зохиол",
    "Далайн түүх",
    "Богино түүх",
    "Спорт",
    "Супер баатар",
    "Триллер",
    "Хотын",
    "Хий үзэгдэл болон метафизик",
    "Дайн болон цэрэг",
    "Өрнөд",
  ],
  Баримтат: [],
  "Хүүхдийн зохиол": [],
  "Хүүхдийн баримтат": [],
  "Комик болон график дүрслэлтэй зохиол": [
    "Ерөнхий",
    "Түүвэр зохиол",
    "Орчин үеийн эмэгтэй",
    "Гэмт хэрэг ба нууцлаг",
    "Эротик",
    "Фантастик",
    "Түүхэн зохиол",
    "Аймшиг",
    "ЛГБТ",
    "Уран зохиол",
    "Манга",
    "Хэвлэл мэдээллээс сэдэвлэсэн",
    "Уран зохиолын бус",
    "Шашин шүтлэг",
    "Хайр/Романс",
    "Шинжлэх ухааны зохиол",
    "Супер баатар",
  ],
  "Боловсрол ба тайлбар": [
    "Боловсрол",
    "Гадаад хэл судлал",
    "Тайлбар",
    "Сургалтын mусламж",
  ],
  "Уран зохиолын цуглуулга": [],
  Ангилалгүй: [],
};

const initialFormState2 = {
  genre: {
    label: "Үндсэн жанр сонгох",
    items: [
      { value: "Зохиол" },
      { value: "Баримтат" },
      { value: "Хүүхдийн зохиол" },
      { value: "Хүүхдийн баримтат" },
      { value: "Комик болон график дүрслэлтэй зохиол" },
      { value: "Боловсрол ба тайлбар" },
      { value: "Уран зохиолын цуглуулга" },
      { value: "Ангилалгүй" },
    ],
    name: "genre",
    id: "genre",
    value: "",
    validationRules: [requiredRule()],
    error: "",
  },
  category: {
    label: "Үндсэн ангилал сонгох",
    items: categories["Зохиол"].map((category) => {
      return { value: category };
    }),
    name: "category",
    id: "category",
    value: "",
    validationRules: [requiredRule()],
    error: "",
  },
  subgenre: {
    label: "Дэд жанр сонгох",
    items: [
      { value: "Зохиол" },
      { value: "Баримтат" },
      { value: "Хүүхдийн зохиол" },
      { value: "Хүүхдийн баримтат" },
      { value: "Комик болон график дүрслэлтэй зохиол" },
      { value: "Боловсрол ба тайлбар" },
      { value: "Уран зохиолын цуглуулга" },
      { value: "Ангилалгүй" },
    ],
    name: "subgenre",
    id: "subgenre",
    value: "",
    validationRules: [],
    error: "",
  },
  subcategory: {
    label: "Дэд ангилал сонгох",
    items: categories["Зохиол"].map((category) => {
      return { value: category };
    }),
    name: "subcategory",
    id: "subcategory",
    value: "",
    validationRules: [],
    error: "",
  },
};

export default initialFormState2;
