import Button from "./Button";

function SecondaryButton({ className, children, ...other }) {
  return (
    <Button
      className={`bg-gray-200 hover:bg-gray-300 text-black ${className || ""}`}
      {...other}
    >
      {children}
    </Button>
  );
}

export default SecondaryButton;
