import Input from "../Input";
import { forwardRef } from "react";

function CodeField(props, ref) {
  const { isActive = true, type, ...other } = props;
  const className = isActive ? "bg-white" : "bg-gray-200";

  return (
    <Input
      type="text"
      className={`mt-1 block w-12 rounded-md text-center ${className}`}
      ref={ref}
      maxLength={1}
      disabled={!isActive}
      {...other}
    />
  );
}

export default forwardRef(CodeField);
