import React from "react";

function NumberIcon({ number, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
      className={className}
    >
      <circle cx="10" cy="10" r="8"></circle>
      <text
        x="10"
        y="14"
        textAnchor="middle"
        fill="#fff"
        className="text-xs select-none"
      >
        {number}
      </text>
    </svg>
  );
}

export default NumberIcon;
