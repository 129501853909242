import React from "react";
import {PrimaryButton, SecondaryButton} from "../../../components/Button";

function PublishingActions({ activeStep, handleGoForward, handleGoBack }) {
  return (
    activeStep <= 4 && (
      <div className="mt-10 flex justify-end">
        {activeStep > 1 && (
          <SecondaryButton className="w-40 mr-5 text-center" onClick={handleGoBack}>
            Буцах
          </SecondaryButton>
        )}
        {
          <PrimaryButton className="w-40 text-center" onClick={handleGoForward}>
            {activeStep < 4 ? "Үргэлжлүүлэх" : "Нийтлэх"}
          </PrimaryButton>
        }
      </div>
    )
  );
}

export default PublishingActions;
