import React from "react";

function Divider() {
  return (
    <div className="py-5">
      <div
        className="hidden sm:block border-t border-gray-200"
        aria-hidden="true"
      />
    </div>
  );
}

export default Divider;
