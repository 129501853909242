import React from "react";
import { NavLink, useLocation } from "react-router-dom";

function NavBarItem({ to, title }) {
  const { pathname } = useLocation();
  const isActive = pathname === to;

  return (
    <li>
      <NavLink
        to={to}
        className={`flex flex-row items-center h-10 px-3 rounded-lg hover:bg-white hover:text-blue-500 ${
          isActive ? "text-blue-500 " : "text-black"
        }`}
      >
        {title}
      </NavLink>
    </li>
  );
}

export default NavBarItem;
