import {
  maxLengthRule,
  requiredRule,
} from "../../../../utils/inputValidationRules";

const contributor = {
  type: {
    label: "Эрхлэн гаргахад оролцсон",
    items: [
      { value: 8, label: "Зохиогч" },
      { value: 9, label: "Эрхлэн гаргагч" },
      { value: 10, label: "Редактор" },
      { value: 11, label: "Өмнөх үг бичсэн" },
      { value: 12, label: "Зураач, чимэглэгч" },
      { value: 13, label: "Оршил бичсэн" },
      { value: 14, label: "Хойлойг уншсан" },
      { value: 15, label: "Гэрэл зурагчин" },
      { value: 16, label: "Орчуулагч" },
      { value: 17, label: "Дизайнер" },
    ],
    name: "type",
    value: "",
    validationRules: [],
    error: "",
  },
  first_name: {
    label: "Нэр",
    name: "first_name",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
  last_name: {
    label: "Овог",
    name: "last_name",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
};

const keyword = {
  keyword: {
    label: "Түлхүүр үг",
    name: "keyword",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
};

const author = {
  first_name: {
    label: "Нэр",
    name: "first_name",
    id: "first_name",
    value: "",
    validationRules: [requiredRule(), maxLengthRule(255)],
    error: "",
  },
  last_name: {
    label: "Овог",
    name: "last_name",
    id: "last_name",
    value: "",
    validationRules: [requiredRule(), maxLengthRule(255)],
    error: "",
  },
};

const initialFormState1 = {
  bookFormat: {
    name: "bookFormat",
    items: [
      {
        value: "Цахим ном",
        checked: false,
      },
      {
        value: "Аудио ном",
        checked: false,
      },
    ],
    value: [],
    validationRules: [requiredRule()],
    error: "",
  },
  title: {
    label: "Номын нэр",
    name: "title",
    id: "title",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
  sub_title: {
    label: "Дэд нэр эсвэл туслах гарчиг",
    name: "sub_title",
    id: "sub_title",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
  original_language: {
    label: "Ном бичэгдсэн хэл",
    items: [
      { value: "Монгол хэл" },
      { value: "Англи хэл" },
      { value: "Орос хэл" },
    ],
    name: "original_language",
    id: "original_language",
    value: "",
    validationRules: [requiredRule()],
    error: "",
  },
  is_translated: {
    label: "Орчуулгын бүтээл",
    name: "is_translated",
    value: false,
    validationRules: [],
    error: "",
  },
  foreign_title: {
    label: "Англи хэл дээрхи нэр",
    name: "foreign_title",
    id: "foreign_title",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },

  // Series
  is_series: {
    label: "Цуврал",
    name: "is_series",
    value: false,
    validationRules: [],
    error: "",
  },
  series_title: {
    label: "Цувралын нэр",
    name: "series_title",
    id: "series_title",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
  series_number: {
    label: "Цувралын дугаар",
    name: "series_number",
    id: "series_number",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },

  //Edition
  is_edition: {
    label: "Хэвлэлийн дугаарлал",
    name: "is_edition",
    value: false,
    validationRules: [],
    error: "",
  },
  edition_number: {
    label: "",
    name: "edition_number",
    id: "edition_number",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },

  //Author
  author_id: {
    label: "Анхдагч зохиогч",
    name: "author_id",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
    searchTerm: "",
    items: [
      {
        value: 1,
        label: "Wade Cooper",
      },
      {
        value: 2,
        label: "Arlene Mccoy",
      },
      {
        value: 3,
        label: "Devon Webb",
      },
    ],
  },
  new_authors: [],

  //Contributors
  contributors: [{ ...contributor }],
  //Critic and Review
  critic: {
    label: "Нэр оруулах",
    name: "critic",
    id: "critic",
    value: "",
    validationRules: [maxLengthRule(255)],
    error: "",
  },
  review: {
    label: "Сэтгэгдэл/ шүүмж оруулах",
    name: "review",
    id: "review",
    value: "",
    validationRules: [maxLengthRule(500)],
    error: "",
  },

  //Copyright
  right: {
    name: "right",
    items: [
      {
        label:
          "Би энэхүү ном (бүтээл)-ын Оюуны өмч, Зохиогчийн эрх болон түүнд хамаарах эрхийг эдэлдэг.",
        value: "private",
      },
      {
        label: "Энэ бол нийтийн оюуны өмчийн бүтээл.",
        value: "public",
      },
    ],
    value: "",
    validationRules: [requiredRule()],
    error: "",
  },

  //Keywords
  keywords: [{ ...keyword }],

  //Age restriction
  restriction_min_age: {
    label: "Насны доод хязгаарыг сонгох",
    items: [
      ...[...Array(18).keys()].map((value) => {
        return { value: value.toString() };
      }),
      { value: "18+" },
    ],
    name: "restriction_min_age",
    id: "restriction_min_age",
    value: "",
    validationRules: [requiredRule()],
    error: "",
  },
  restriction_max_age: {
    label: "Насны дээд хязгаарыг сонгох",
    items: [
      ...[...Array(18).keys()].map((value) => {
        return { value: value.toString() };
      }),
      { value: "18+" },
    ],
    name: "restriction_max_age",
    id: "restriction_max_age",
    value: "",
    validationRules: [requiredRule()],
    error: "",
  },
};

export default initialFormState1;
export { contributor, keyword, author };
