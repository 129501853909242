import React from "react";
import { CloudUploadIcon } from "@heroicons/react/solid";
import { getFileInfo } from "../../utils/fileHandlers";

function FileInputWrapper({ children, file, error }) {
  const { fileName, fileSize } = getFileInfo(file);
  const isValid = !!!error;
  const currentColor = isValid ? "text-blue-500" : "text-red-500";
  const borderColor = isValid ? "border-gray-300" : "border-red-500";

  return (
    <div
      className={`relative h-36 mt-1 w-full rounded-md
                 border-dashed border-2 border-gray-300 bg-white shadow-sm 
                 flex justify-center items-center overflow-hidden
                 ${borderColor}`}
    >
      <div className="absolute">
        <div className="flex flex-col items-center">
          <CloudUploadIcon className={`h-10 w-10 ${currentColor}`} />
          <span
            className={`block font-normal text-lg text-center ${currentColor}`}
          >
            {!!fileName ? fileName : "Файл оруулах"}
          </span>
          <span
            className={`block font-normal text-lg text-center ${currentColor}`}
          >
            {!!fileSize && fileSize}
          </span>
        </div>
      </div>
      {children}
    </div>
  );
}

export default FileInputWrapper;
