import Button from "./Button";

function LinkButton({ children, className, href, ...other }) {
  return (
    <Button
      className={`bg-blue-600 hover:bg-blue-800 text-white ${className}`}
      href={href}
      {...other}
    >
      {children}
    </Button>
  );
}

export default LinkButton;
