import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
} from "../components/Table";
import Menu from "../components/Menu";

const books = [
  {
    title: "Хуучин байшин",
    author: "Р. Оюунжаргал",
    image:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    date: "2020-06-17T03:55:17.290Z",
    numberOfViews: "681",
    numberOfComments: "12",
    readingMode: "6000 / 16",
    listeningMode: "8000 / 3",
    soldQuantity: "20",
  },
  {
    title: "Хуучин байшин",
    author: "Р. Оюунжаргал",
    image:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    date: "2020-06-17T03:55:17.290Z",
    numberOfViews: "681",
    numberOfComments: "12",
    readingMode: "6000 / 16",
    listeningMode: "8000 / 3",
    soldQuantity: "20",
  },
  {
    title: "Хуучин байшин",
    author: "Р. Оюунжаргал",
    image:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    date: "2020-06-17T03:55:17.290Z",
    numberOfViews: "681",
    numberOfComments: "12",
    readingMode: "6000 / 16",
    listeningMode: "8000 / 3",
    soldQuantity: "20",
  },
  {
    title: "Хуучин байшин",
    author: "Р. Оюунжаргал",
    image:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    date: "2020-06-17T03:55:17.290Z",
    numberOfViews: "681",
    numberOfComments: "12",
    readingMode: "6000 / 16",
    listeningMode: "8000 / 3",
    soldQuantity: "20",
  },
  {
    title: "Хуучин байшин",
    author: "Р. Оюунжаргал",
    image:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    date: "2020-06-17T03:55:17.290Z",
    numberOfViews: "681",
    numberOfComments: "12",
    readingMode: "6000 / 16",
    listeningMode: "8000 / 3",
    soldQuantity: "20",
  },
];

function BookTable() {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Бүтээл</TableHeaderCell>
          <TableHeaderCell>Сонирхсон</TableHeaderCell>
          <TableHeaderCell>Сэтгэгдэл</TableHeaderCell>
          <TableHeaderCell>Унших хэлбэр</TableHeaderCell>
          <TableHeaderCell>Сонсох хэлбэр</TableHeaderCell>
          <TableHeaderCell>Борлуулагдсан тоо</TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {books.map((book, idx) => (
          <TableRow key={idx}>
            <TableCell>
              <div className="flex items-center">
                <div className="flex-shrink-0 h-22 w-20">
                  <img className="h-20 w-20" src={book.image} alt="" />
                  <div className="text-sm text-gray-500 pt-2 text-center">
                    {new Date(book.date).toLocaleDateString()}
                  </div>
                </div>
                <div className="ml-4">
                  <div className="text-sm font-medium text-gray-900">
                    {book.title}
                  </div>
                  <div className="text-sm text-gray-500">{book.author}</div>
                </div>
              </div>
            </TableCell>
            <TableCell className="text-sm">{book.numberOfViews}</TableCell>
            <TableCell className="text-sm">{book.numberOfComments}</TableCell>
            <TableCell className="text-sm">{book.readingMode}</TableCell>
            <TableCell className="text-sm">{book.listeningMode}</TableCell>
            <TableCell className="text-sm">{book.soldQuantity}</TableCell>
            <TableCell>
              <Menu />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default BookTable;
