import React from "react";

function Card({ children, className }) {
  return (
    <div
      className={`${
        className || "w-64"
      } p-4 rounded-lg text-black text-left space-y-5 
      bg-white hover:bg-gray-100 shadow-md border border-gray-200 
      transform hover:scale-110 
      transition duration-100 ease-in-out`}
    >
      {children}
    </div>
  );
}

export default Card;
