import React from "react";
import Label from "../Label";
import Input from "../Input";
import Error from "../Error";

function TextArea(props) {
  const { label, value, ...other } = props;
  const error = other.error;
  const rows = other.rows || 6;

  return (
    <>
      <Label>{label}</Label>
      <Input
        type="textarea"
        className="mt-1 block w-full rounded-md resize-none border"
        rows={rows}
        defaultValue={value}
        {...other}
      />
      <Error>{error}</Error>
    </>
  );
}

export default TextArea;
