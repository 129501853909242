import React from "react";

function FormSectionContent({ children }) {
  return (
    <div className="mt-5 md:mt-0 md:col-span-2">
      <div className="py-5 sm:py-0">{children}</div>
    </div>
  );
}

export default FormSectionContent;
